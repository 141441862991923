import React from 'react'
import { translations } from '../../translate'
import './styles.scss'
import imgContact from '../../assets/images/contactUs.png'
import imgContactMob from '../../assets/images/contactUsMob.png'
import logoCall from '../../assets/images/callLogo.png'
import logoEmail from '../../assets/images/emailLogo.png'
import logoLocation from '../../assets/images/locationLogo.png'

function ContactUs({ language }) {
    return (
        <>
            <div className='contactUsWrapper'>
                <div className='imgContainerContactUs'>
                    <img className='imgContactUs' src={imgContact} alt="contact one" />
                    <img className='imgContactUsMob' src={imgContactMob} alt="contact two" />

                    <div className='overlay3'>

                        <div className='contactAndLink'>
                            <div className='contactLogo'>
                                <img className='imgLogo' src={logoCall} alt="contact logo one" />
                            </div>

                            <div className='nameLogo'>
                                <p>
                                    {translations[language]?.callUsAndNumber}
                                </p>
                            </div>
                            <div className='nameLogo'>
                                <i>+244 941 078 666</i>
                            </div>
                        </div>

                        <div className='contactAndLink' >
                            <div className='contactLogo'>
                                <img className='imgLogo' src={logoEmail} alt="contact logo two" />
                            </div>
                            <div className='nameLogo'>
                                vendas@apreciosahotel.com
                            </div>
                        </div>

                        <div className='contactAndLink'>
                            <div className='contactLogo'>
                                <img className='imgLogo' src={logoLocation} alt="contact logo three" />
                            </div>
                            <div className='nameLogo'>
                                <p>
                                    {translations[language]?.map}
                                </p>
                            </div>
                            <div className='nameLogo'>
                                Avenida 21 de Janeiro, morro bento
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export default ContactUs