export const links = [
    {
        id: 'home',
        navigate: '/',
        url: 'home'
    },
    {
        id: 'services',
        navigate: '/services',
        url: 'services'
    },
    {
        id: 'contactus',
        navigate: '/contactus',
        url: 'contactus'
    },
    {
        id: 'about',
        navigate: '/about',
        url: 'about'
    },
]