export const services = [
    {

        id: 'service1',
        name: 'Brunch',
        parag: 'Brunch2',
        url: 'brunch-min.png',
        urlMob: 'brunchMob-min.png',
        displayLine: true,
    },
    {
        id: 'service2',
        name: 'Nayaki',
        parag: 'Nayaki2',
        url: 'nayaki-min.png',
        urlMob: 'nayakiMob-min.png',
        displayLine: false,
    },
    {
        id: 'service3',
        name: 'Nayaki3',
        parag: 'Nayaki4',
        url: 'nayaki2-min.png',
        urlMob: 'nayakiMob2-min.png',
        displayLine: false,
    },
    {
        id: 'service4',
        name: 'Royo',
        parag: 'Royo2',
        url: 'royo-min.png',
        urlMob: 'royoMob-min.png',
        displayLine: false,
    },
    {
        id: 'service5',
        name: 'Royo3',
        parag: 'Royo4',
        url: 'royo2-min.png',
        urlMob: 'royoMob2-min.png',
        displayLine: true,
    },
    {
        id: 'service6',
        name: 'Gym',
        parag: 'Gym2',
        url: 'gym-min.png',
        urlMob: 'gymMob-min.png',
        displayLine: true,
    },
    {
        id: 'service7',
        name: 'SwimmingPool',
        parag: 'SwimmingPool2',
        url: 'swim-min.png',
        urlMob: 'swimMob-min.png',
        displayLine: true,
    },
    {
        id: 'service8',
        name: 'ConferenceRoom',
        parag: 'ConferenceRoom2',
        url: 'conferenceRoom-min.png',
        urlMob: 'conferenceRoomMob-min.png',
        displayLine: false,
    },
    {
        id: 'service9',
        name: 'ConferenceRoom3',
        parag: 'ConferenceRoom4',
        url: 'conferenceRoom2-min.png',
        urlMob: 'conferenceRoomMob2-min.png',
        displayLine: true,
    },
]