export const translations = {

    en: {
        home: 'home',
        services: 'services',
        contactus: 'contact us',
        about: 'about',
        text: 'landing page',
        joinUs1: 'Join Us',
        follow: 'Give us a follow',
        breif1: 'Elegance and comfort are our signature.',
        breif2: 'The A Preciosa Hotel is an ideal spot to work or simply unwind. Our goal is to provide our visitors with all they need to have a comfortable and restful stay.',
        bookNow: 'Book Now',
        weOffer: 'WE OFFER YOU',
        Reception: '24h Reception',
        RoomService: 'Room Service',
        Freewifi: 'Free-Wifi',
        LaundryService: 'Laundry Service',
        Gymnasium: 'Gymnasium',
        OutdoorSwimmingPool: 'Outdoor Swimming Pool',
        TwoInternationalRestaurants: 'Two International Restaurants',
        APub: 'A Pub',
        callUs: 'Call Us',
        or: 'OR',
        email: 'E-mail Us',
        Brunch: 'Brunch',
        Brunch2: 'The A Preciosa hotel has a Brunch service every Sunday, from 11:00 am to 4:00 pm located on the 0th floor of the hotel, with an endless buffet in sight, charcuterie and cheese, various breads and viennoiserie, miniatures of all the sweets you can imagine, fruit, salads, egg station and pancakes, sushi, seafood, and a wide selection of hot and cold dishes.',
        Nayaki: 'RESTAURANTE NAYAKI',
        Nayaki2: 'An elegant space with sophisticated decor and an intimate atmosphere. Located on the 3rd floor of the hotel. At Preciosa, the Nayaki restaurant is a new experience for all the senses, reinterpreting the fusion of Asian cuisine. The menu also includes cocktails and wines, a very refined decoration and a wonderful team ready to welcome you.',
        Nayaki4: 'The Nayaki Restaurant is a true trip to Asia for food lovers or adventurers. exotic flavors. The restaurant has two elegant and intimate rooms, a smoking area and non-smoking, a sushi bar and a cocktail bar with a splendid view.',
        Royo: 'RESTAURANTE ROYO',
        Royo2: 'Royo is an international cuisine restaurant in a vast, modern and sophisticated space. Great option for business lunches, delicious dinners with family or friends and an excellent choice for busy nights in Luanda.',
        Royo4: 'Come for dinner, or just for a drink, we are sure you will love the experience. One gastronomy of excellence in an environment that is simultaneously sophisticated, relaxed and elegant. Royo promises to become a reference in the city of Luanda.',
        Gym: 'GYM',
        Gym2: 'The A Preciosa hotel has the ideal space to stimulate the body with exercise. A Fitness room on the 2nd floor of the hotel with 24/7 access, perfect for your private fitness classes, pilates or simply for those who want to start the day with a run on the treadmill.',
        SwimmingPool: 'SWIMMING POOL',
        SwimmingPool2: 'Enhancing the experience of our guests, the A Preciosa hotel has a swimming pool outdoor area for adults and children, located on the 0th floor of the hotel, perfect for relaxing and enjoy the sunny days of Luanda, with special or classic signature cocktails, always accompanied by our delicious snack menu.',
        ConferenceRoom: 'CONFERENCE ROOM',
        ConferenceRoom2: 'The A Preciosa hotel has a conference and events room on the 3rd floor, measuring 140 square meters, versatile with plenty of natural light and a capacity of up to 170 participants. The perfect venue for all types of events, it offers incredible flexibility for host everything from cocktails, exhibitions, launch events, gala dinners, wedding and other social and corporate events. ',
        ConferenceRoom4: 'We have for you a place with complete service, available for additional requirements and services. All our proposals are accompanied by the possibility of being personalized. Our specialist consultants plan, organize and guarantee all aspects of your event and catering service.',
        aboutTitle: 'Elegance and comfort are our signature.',
        aboutParag: 'Our Hotel has 6 types of rooms (Standard Single, Standard Double, Executive Single, Executive Double, Deluxe Room and a Presidential Suite). Elegance and comfort are our signature. Our rooms offer serenity for leisure and/or work and a wide range of 24/7 services available to bring comfort to your stay.',
        callUsAndNumber: 'Give us a call',
        map: 'Google Maps',
    },

    pt: {
        home: 'lar',
        services: 'Serviços',
        contactus: 'Contate-nos',
        about: 'sobre',
        text: 'página de destino',
        joinUs1: 'Junte-se a nós',
        follow: 'Siga-nos',
        joinUs2: 'Avenida 21 de Janeiro, morro bento',
        breif1: 'Elegância e conforto são a nossa assinatura.',
        breif2: 'O hotel A Preciosa é o local perfeito para trabalhar ou simplesmente relaxar. O nosso objectivo é oferecer aos nossos hóspedes tudo que precisam para assegurar uma estádia agradável e tranquila.',
        bookNow: 'Agende agora',
        weOffer: 'O Hotel A Preciosa oferece-lhe',
        Reception: '24h de recepção',
        RoomService: 'room service',
        Freewifi: 'Wi-Fi grátis',
        LaundryService: 'serviço de lavandaria',
        Gymnasium: 'ginásio',
        OutdoorSwimmingPool: 'piscina exterior',
        TwoInternationalRestaurants: 'dois restaurantes internacionais ',
        APub: 'um bar',
        callUs: 'Ligue para nós',
        or: 'OU',
        email: 'Envia-nos um email',
        Brunch: 'BRUNCH ',
        Brunch2: 'O hotel A Preciosa dispõe de um serviço de Brunch todo o domingo, das 11:00 às 16:00 situado no piso 0 do hotel, com um buffet sem fim à vista, charcutaria e queijos, diversos pães e viennoiserie, miniaturas de todos os doces que possa imaginar, fruta, saladas, estação de ovos e panquecas, sushi, marisco, e uma vasta seleção de pratos e quentes.',
        Nayaki: 'RESTAURANTE NAYAKI',
        Nayaki2: 'Um espaço elegante de decoração sofisticada e ambiente intimista. Situado no 3º piso do hotel A Preciosa, o restaurante Nayaki é uma nova experiência para todos os sentidos, reinterpretando a fusão da gastronomia asiática. O menu passa também pelos cocktails e vinhos, uma decoração com muito requinte e uma equipa maravilhosa pronta para o receber. ',
        Nayaki4: 'O Restaurante Nayaki é uma verdadeira viagem a Ásia para os amantes ou aventureiros de sabores exóticos. O restaurante conta com duas salas elegantes e intimistas, área de fumadores e não fumadores, um bar de sushi e um bar de cocktails com uma vista esplendida.',
        Royo: 'RESTAURANTE ROYO',
        Royo2: 'O Royo é um restaurante de gastronomia internacional num espaço vasto, moderno e sofisticado. Óptima opção para almoços de negócios, deliciosos jantares em família ou com amigos e uma excelente escolha para noites agitadas de Luanda.',
        Royo4: 'Venha jantar, ou apenas beber um copo, estamos certos de que vai amar a experiência. Uma gastronomia de excelência num ambiente simultaneamente sofisticado, descontraído e elegante. O Royo promete tornar-se uma referência na cidade de Luanda.',
        Gym: 'GINÁSIO',
        Gym2: 'O hotel A Preciosa tem o espaço ideal para estimular o corpo com exercícios. Uma sala de Fitness no 2º piso do hotel com acesso 24/7, perfeita para a suas aulas privadas de pilates ou simplesmente para quem deseja começar o dia com uma corrida na esteira.',
        SwimmingPool: 'PISCINA',
        SwimmingPool2: 'Engrandecendo a experiencia do nossos hóspedes, o hotel A Preciosa dispõe de uma Piscina exterior para adultos e crianças, localizada no piso 0 do hotel, perfeita para descontrair e aproveitar os dias ensolarados de Luanda, com cocktails de assinaturas especiais ou clássicos, sempre acompanhados com o nosso menu de snacks deliciosos.',
        ConferenceRoom: 'SALA DE CONFERÊNCIAS ',
        ConferenceRoom2: 'O hotel A Preciosa dispõe de uma sala de conferências e eventos no 3º piso, com 140 metros quadrados, versátil com bastante luz natural e uma capacidade até 170 participantes. O local perfeito para todos os tipos de eventos, oferece uma flexibilidade incrível para receber desde cocktails, exposições, eventos de lançamento, jantares de gala, receções de casamento e outros eventos sociais e corporativos. Temos para si um local com um serviço completo, disponível para requisitos e serviços adicionais.',
        ConferenceRoom4: 'Todas as nossas propostas são acompanhadas pela possibilidade de serem personalizadas. Os nossos consultores especializados planeiam, organizam e garantem todos os aspetos do seu evento e serviço de catering.',
        aboutTitle: 'Elegância e conforto são a nossa assinatura.',
        aboutParag: 'O nosso Hotel dispõe de 6 Tipologias de quartos (Standard Singular, Standard Duplo, Executivo Singular, Executivo Duplo, Quarto Deluxe e uma Suite Presidencial). Elegância e conforto são a nossa assinatura. Os nossos quartos oferecem serenidade para lazer e/ou trabalho e uma vasta gama de serviços 24/7 disponíveis para trazer conforto a sua estádia.',
        callUsAndNumber: 'Ligue para nós',
        map: 'Google Maps',
    },

}