import React from 'react'
import { Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import './styles.scss'
import image1 from '../../assets/images/slider1-min.png'
import image2 from '../../assets/images/slider2-min.png'
import image3 from '../../assets/images/slider3-min.png'
import image4 from '../../assets/images/slider4-min.png'
import image5 from '../../assets/images/slider5-min.png'
import image6 from '../../assets/images/slider6-min.png'
import image7 from '../../assets/images/slider7-min.png'
import image8 from '../../assets/images/slider8-min.png'

function Slider() {
  return (

    <Swiper

      modules={[Autoplay]}
      spaceBetween={10}
      breakpoints={{
        400: {
          slidesPerView: 1,
        },
        1000: {
          slidesPerView: 2.25,
        },
      }}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}

    >

      <SwiperSlide className="carousel-cell"><img src={image1} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image2} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image3} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image4} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image5} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image6} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image7} alt="slider apreciosa" /></SwiperSlide>
      <SwiperSlide className="carousel-cell"><img src={image8} alt="slider apreciosa" /></SwiperSlide>

    </Swiper>
  )
}

export default Slider