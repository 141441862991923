import React from 'react'
import { translations } from '../../translate'
import Flower from '../../assets/images/flower-min.png'
import './styles.scss'
import imgAbout from '../../assets/images/about.png'
import imgAboutMob from '../../assets/images/aboutMob.png'

function About({ language }) {
    return (
        <>
            <div className='aboutWrapper'>
                <div className='imgContainerAbout'>
                    <img className='imgAbout' src={imgAbout} alt="about one" />
                    <img className='imgAboutMob' src={imgAboutMob} alt="about two" />
                    <div className='overlay2'>
                        <div className='twoflowers'>
                            <img className='miniFlowerRight' src={Flower} alt="about flower" />
                            <img className='miniFlowerLeft' src={Flower} alt="about flower" />
                        </div>
                        <div className='aboutText'>
                            <p className='nameAbout'>
                                {translations[language]?.aboutTitle}
                            </p>
                            <p className='paragAbout'>
                                {translations[language]?.aboutParag}
                            </p>
                        </div>
                        <div className='twoflowers'>
                            <img className='miniFlowerRight' src={Flower} alt="about flower" />
                            <img className='miniFlowerLeft' src={Flower} alt="about flower" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About