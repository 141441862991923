import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/footer'
import Navigation from '../../components/navbar'

function PublicLayout({ language, setLanguage }) {
    return (
        <>
            <Navigation language={language} />
            <Outlet />
            <Footer language={language} setLanguage={setLanguage} />
        </>
    )
}

export default PublicLayout