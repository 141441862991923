import React from 'react'
import { Modal } from 'react-bootstrap'
import './styles.scss'
import { translations } from '../../translate'

function BookNowModal(props) {
  return (
    <Modal
      className='modalContainer'
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className='modalHeader'>
      </Modal.Header>
      <Modal.Body>
        <div className='lightDarkContainer'>
          <div className='twoDiv'>
            <div className='lightDiv'>
              <p>
                {translations[props.language]?.callUs}
              </p>
            </div>
            <div className='darkDiv'>
              +244 941 078 666
            </div>
          </div>
          <div className='twoDiv'>
            <p>
              {translations[props.language]?.or}
            </p>
          </div>
          <div className='twoDiv'>
            <div className='lightDiv'>
              <p>
                {translations[props.language]?.email}
              </p>
            </div>
            <div className='darkDiv'>
              vendas@apreciosahotel.com
            </div>
          </div>
        </div>
      </Modal.Body>

    </Modal>
  )
}

export default BookNowModal