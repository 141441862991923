import React from 'react'
import JoinUs from '../../components/joinus'
import Slider from '../../components/slider'
import Breif from '../../components/breif'
import WeOffer from '../../components/offers'

function LandingPage({ language }) {
  return (
    <>
      <Slider />
      <JoinUs language={language} />
      <Breif language={language} />
      <WeOffer language={language} />
    </>
  )
}

export default LandingPage