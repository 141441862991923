import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './styles.scss'
import { translations } from '../../translate'

function JoinUs({language}) {
  return (
    <Container fluid >
        <Row>
            <Col lg={12} style={{padding:0}}>
                <div className="Join">
                    <div>
                        <p className="text1">
                           {translations[language]?.joinUs1}
                        </p>
                    </div> 
                    <div className="seperator">
                    </div>
                    <div>
                        <p className="text2">
                            {translations["pt"].joinUs2}
                        </p>
                    </div> 
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default JoinUs