export const offers = [
    {
        id: 'offer1',
        name: 'Reception',
        url: '24hReception-min.png',
        width: '129px',
        height: '129px',
    },
    {
        id: 'offer2',
        name: 'RoomService',
        url: 'RoomService-min.png',
        width: '126px',
        height: '114px',
    },
    {
        id: 'offer3',
        name: 'Freewifi',
        url: 'wifi-min.png',
        width: '96px',
        height: '68px',
    },
    {
        id: 'offer4',
        name: 'LaundryService',
        url: 'laundry-min.png',
        width: '68px',
        height: '85px',
    },
    {
        id: 'offer5',
        name: 'Gymnasium',
        url: 'gymlogo-min.png',
        width: '95px',
        height: '95px',
    },
    {
        id: 'offer6',
        name: 'OutdoorSwimmingPool',
        url: 'swimlogo-min.png',
        width: '104px',
        height: '99px',
    },
    {
        id: 'offer7',
        name: 'TwoInternationalRestaurants',
        url: 'intRes-min.png',
        width: '97px',
        height: '97px',
    },
    {
        id: 'offer8',
        name: 'APub',
        url: 'pub-min.png',
        width: '91px',
        height: '91px',
    },

]