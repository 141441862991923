import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './styles.scss'
import { translations } from '../../translate'
import Flower from '../../assets/images/flower-min.png'

function Breif({ language }) {
    return (
        <div className="breifContainer">
            <Container>

                <Row>
                    <Col lg={3} md={12} sm={12}>
                        <div className='right'>

                            <div className='rl'>
                                <img src={Flower} className='f' alt='breif one' />
                            </div>

                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12}>
                        <div className='bigText'>
                            <div>
                                <p className="text3">
                                    {translations[language]?.breif1}
                                </p>
                            </div>
                            <div>
                                <p className="text4">
                                    {translations[language].breif2}
                                </p>
                            </div>
                        </div>
                    </Col>

                    <Col lg={3} md={12} sm={12}>
                        <div className='left'>

                            <div className='ll'>
                                <img src={Flower} className='f' alt='breif two' />
                            </div>

                        </div>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}

export default Breif