import React from 'react'
import { services } from './servicesdata.js'
import { Container, Row } from 'react-bootstrap'
import { translations } from '../../translate'
import Flower from '../../assets/images/flower-min.png'
import './styles.scss'

function Services({ language }) {
    return (
        <>
            <Container fluid >
                {
                    services.map(service => (
                        <Row key={service.id}>
                            <div className='ServiceWrapper'>
                                <div className='imgContainerService'>
                                    <img className='imgService' src={require(`../../assets/images/${service.url}`)} alt="service one" />
                                    <img className='imgServiceMob' src={require(`../../assets/images/${service.urlMob}`)} alt="service two" />
                                    <div className='overlay'>
                                        <p className='nameService'>
                                            {translations[language]?.[service.name]}
                                        </p>
                                        <p className='paragService'>
                                            {translations[language]?.[service.parag]}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {
                                service.displayLine ?
                                    <>
                                        <div className='seperatingFrower'>
                                            <div className='sep'>
                                            </div>
                                            <div className='flowerSep'>
                                                <img src={Flower} className='g' alt='flower' />
                                            </div>
                                            <div className='sep'>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </Row>
                    ))
                }
            </Container>


        </>
    )
}

export default Services