import React, { useState } from 'react';
import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import PublicLayout from './layout/publiclayout';
import LandingPage from './pages/landing';
import Services from './pages/services';
import ContactUs from './pages/contactus';
import About from './pages/about';

function App() {

  const [language, setLanguage] = useState('pt');



  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<PublicLayout language={language} setLanguage={setLanguage} />}>
            <Route path="/" element={<LandingPage language={language} />} />
            <Route path="/services" element={<Services language={language} />} />
            <Route path="/contactus" element={<ContactUs language={language} />} />
            <Route path="/about" element={<About language={language} />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
