import React, { useState } from 'react'
import './styles.scss'
import { translations } from '../../translate'
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import NavbarLinks from '../navlinks';
import { links } from './navLinkData';
import BookNowModal from '../modal';

function Navigation({ language }) {



    const [modalShow, setModalShow] = useState(false);



    return (
        <>
            <Navbar expand="lg" className='navbarWrapper'>
                <Container fluid>
                    <Navbar.Brand>A PRECIOSA</Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >

                            {
                                links.map(link => (

                                    <NavbarLinks key={link.id} navigate={link.navigate} url={translations[language]?.[link.url]} />

                                ))
                            }

                        </Nav>
                        <Button onClick={() => setModalShow(true)} className='bookNowButton'>
                            <p>
                                {translations[language]?.bookNow}
                            </p>
                        </Button>

                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <BookNowModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                language={language}
            />
        </>
    )
}

export default Navigation