import React from 'react'
import { offers } from './offersdata.js'
import { Container, Row, Col } from 'react-bootstrap'
import { translations } from '../../translate'
import './styles.scss'

function WeOffer({ language }) {
    return (
        <>
            <Container fluid style={{ padding: '0px' }}>
                <div className='OffersBigBox'>
                    <Row>
                        <div className='weofferContainer'>
                            <div className='weOffer'>
                                <p>
                                    {translations[language]?.weOffer}
                                </p>
                            </div>
                            <div className='seperatorOffer'>

                            </div>
                        </div>
                    </Row>
                    <Row>
                        {
                            offers.map(offer => (
                                <Col lg={3} md={6} sm={6} key={offer.id}>
                                    <div className='offerMom'>
                                        <div className='imgContainerOffer'>
                                            <img className='imgOffer' style={{ width: `${offer.width}`, height: `${offer.height}` }} src={require(`../../assets/images/${offer.url}`)} alt="offers" />
                                        </div>
                                        <div>
                                            <p className='nameOffer'>
                                                {translations[language]?.[offer.name]}
                                            </p>
                                        </div>
                                    </div>
                                </Col>

                            ))
                        }
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default WeOffer