import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './styles.scss'

function NavbarLinks(props) {

    const location = useLocation();

    const isActive = location.pathname === props.navigate;

    return (
        <Link to={props.navigate} className={`navLinks ${isActive ? 'navLinks-active' : ''}`}>
            {props.url}
        </Link>
    )
}

export default NavbarLinks